<template>
  <div id="container" :style="'height:' + (showSearchResult ? '60vh' : '100vh')">
  </div>
  <!-- <div id="info" v-if="mapCenter">
    <p>坐标：<span id="position">{{ mapCenter.lat + "," + mapCenter.lng }}</span></p>
  </div> -->
  <div class="search-container"
    :style="'height:' + (showSearchResult ? '40vh' : (inputFocus ? '55px;padding-bottom:0;' : '120px;padding-bottom:0;'))">
    <div class="search-input-container">
      <input type="text" v-model="locationName" @focus="inputFocus = true" @blur="onSearchInputBlur()"
        placeholder="搜索地点.." />
      <a href="javascript:;" @click="searchLocation()" class="btn-search">搜索</a>
    </div>
    <div class="current-location-conatiner" :style="'height:' + (showSearchResult ? '40px' : '100px')">
      <div v-if="mapCenter" class="current-location">
        坐标：{{ mapCenter.lat + "," + mapCenter.lng }}
      </div>
      <div class="btn-confirm-location" v-if="!inputFocus" @click="onConfirmLocation()">
        确认坐标
      </div>
    </div>
    <div v-if="searchResult && showSearchResult" class="search-result-container">
      <div v-for="  (item, index)   in   searchResult.data  " :key="item.id" class="address-item"
        @click="onSearchResultSelected(item, index)">
        <div class="location-icon"><img src="../assets/location.png" /></div>
        <div class="title">{{ item.title }}</div>
        <div class="address">{{ item.address }}</div>
        <div v-if="searchResultActiveIndex == index" class="active-icon"><img src="../assets/checked.png" /></div>
      </div>
    </div>

  </div>
</template>

<script setup>
document.title = "地图选点"
import { onMounted, ref } from 'vue'
import { jsonp } from "vue-jsonp";
import { useRoute } from 'vue-router';
const route = useRoute();

const searchResult = ref(0)
const searchResultActiveIndex = ref(0)
const mapCenter = ref(0)
const inputFocus = ref(0)
const showSearchResult = ref(0)
const activeLocation = ref(0)


searchResultActiveIndex.value = 0
inputFocus.value = false
showSearchResult.value = false

if (route.query && route.query.lat && route.query.lng) {
  mapCenter.value = { lat: route.query.lat, lng: route.query.lng }
}
else {
  mapCenter.value = { lat: 41.945596, lng: 123.42654 }
}


let locationName = ''

let map = null;
function initMap() {
  // let position = document.getElementById("position");
  // let txt = document.getElementById("txt");
  let center = new TMap.LatLng(mapCenter.value.lat, mapCenter.value.lng);//设置中心点坐标
  //初始化地图
  map = new TMap.Map("container", {
    center: center
  });
  // location.innerHTML = map.getCenter().toString();
  //监听地图开始平移
  // map.on("panstart", function () {
  //   txt.innerHTML = "地图开始平移"
  // })
  //监听地图平移
  map.on("pan", function () {
    // txt.innerHTML = "地图正在平移";
    // position.innerHTML = map.getCenter().toString();//获取地图中心点
    mapCenter.value = map.getCenter()
  })
  //监听地图平移结束
  // map.on("panend", function () {
  //   txt.innerHTML = "地图结束平移";
  // })

  map.on("click", function () {
    showSearchResult.value = false
  })

  let markerGeo = {
    id: 'center',
    position: map.getCenter(),
  };

  // 创建一个位于地图中心点的marker
  let markerLayer = new TMap.MultiMarker({
    map: map,
    geometries: [
      markerGeo
    ]
  });
  // 监听中心点变化事件，更新marker的位置
  map.on('center_changed', () => {
    markerGeo.position = map.getCenter();
    markerLayer.updateGeometries([markerGeo]);
  });
}


// 组件挂载后，渲染对应组件
onMounted(() => {
  initMap()
})

function onSearchInputBlur() {
  inputFocus.value = false
  if (!showSearchResult.value) {
    searchLocation()
  }
}

// 通过地址搜索
function searchLocation() {
  inputFocus.value = false
  if (locationName) {
    const params = {
      key: process.env.VUE_APP_QQMAP_API_KEY,
      output: 'jsonp',
      keyword: locationName,
      boundary: 'nearby(41.945596,123.42654,1000,1)'
    };
    jsonp('https://apis.map.qq.com/ws/place/v1/search', params).then(data => {
      console.log(data)
      searchResult.value = data
      if (data && data.data && data.data.length > 0) {
        onSearchResultSelected(data.data[0], 0)
        activeLocation.value = data.data[0]
        showSearchResult.value = true
      }
    })
  }
}

function onSearchResultSelected(item, index) {
  searchResultActiveIndex.value = index
  activeLocation.value = item
  let center = new TMap.LatLng(item.location.lat, item.location.lng);//设置中心点坐标
  map.setCenter(center);
}


document.addEventListener('keydown', function (event) {
  if (event.key === 'Enter') {
    // 确认键被按下，执行相关操作
    searchLocation()
    document.activeElement.blur();
  }
});

function onConfirmLocation() {
  wx.miniProgram.navigateBack({
    delta: 1,
  })
  // wx.miniProgram.redirectTo(`/pages/common/select-location/index`)
  wx.miniProgram.postMessage({ data: { latLng: mapCenter.value, location: activeLocation.value } })
  console.log(mapCenter.value)
  // wx.miniProgram.navigateBack({
  //   delta: 5000,
  // })
}


// function onMapClick() {
//   showSearchResult.value = false
// }

</script>
<style lang="less" scoped>
html,
body {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

#container {
  position: relative;
  width: 100%;
  height: 100vh;
}

#info {
  position: absolute;
  top: 0;
  left: 10px;
  border-radius: 3px;
  font-size: 12px;
  color: #777;
}

.search-container {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  height: 40vh;
  width: 100%;
  padding-bottom: 120px;

  .search-input-container {
    position: absolute;
    width: 90%;
    margin: 0 5%;
    top: 0px;
    left: 0;

    input {
      width: calc(100% - 70px);
      height: 36px;
      line-height: 30px;
      padding: 3px 60px 3px 10px;
      font-size: 16px;
      border: none;
      border-radius: 50px;
      background-color: #fff;
    }

    input:focus {
      outline: none;
      /* 移除默认的蓝色轮廓线 */
    }

    .btn-search {
      position: absolute;
      z-index: 9999999;
      top: 5px;
      right: 5px;
      display: inline-block;
      width: 50px;
      background-color: #3651d4;
      color: #fff;
      text-align: center;
      height: 32px;
      line-height: 34px;
      border-radius: 50px;
      text-decoration: none;
    }
  }

  .search-result-container {
    background-color: #fff;
    overflow-y: auto;
    height: 45vh;

    .address-item {
      margin: 0 10px;
      padding: 10px 0 10px 20px;
      border-bottom: 1px solid #eee;
      position: relative;

      .location-icon {
        position: absolute;
        left: 0;
        top: 10px;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .title {
        font-size: 14px;
      }

      .address {
        font-size: 10px;
        color: #999;
        font-size: 0.9em;
      }

      .active-icon {
        position: absolute;
        right: 5px;
        top: 10px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

  }

  .current-location-conatiner {
    width: 100%;
    height: 40px;
    line-height: 45px;
    background-color: #fff;
    margin-top: 50px;
    position: relative;
    border-bottom: 1px solid #eee;

    .current-location {
      padding-left: 10px;
      font-size: 13px;
      color: #777;
    }

    .btn-confirm-location {
      text-align: center;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 5px;
      background-color: #3651d4;
      height: 30px;
      display: inline-block;
      width: 80px;
      line-height: 34px;
      border-radius: 5px;
    }
  }

}
</style>