import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import {http} from './axios/index';




// // 创建axios实例
// const service = axios.create({
//     // API的base_url
//     baseURL: process.env.VUE_APP_BASE_API,
//     // 请求超时时间
//     timeout: 5000
// });

const app = createApp(App)

// 将axios设置为Vue的原型属性，这样在任何组件中都可以通过this.$http访问
// app.config.globalProperties.$http = service;


app.use(router).mount('#app')
