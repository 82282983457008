<template>
  <div>
  </div>
  <router-view />
</template>

<script>


export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {

  }
};
</script>

<style></style>
